.s-alert-error {
  background: #ec5161;
}
.s-alert-success {
  background: #389bfe;
}
.s-alert-close {
  text-indent: 0;
  top: 7px;
}
.s-alert-close .material-icon {
  font-size: 18px;
}
.s-alert-close::before {
  display: none;
}

.s-alert-close::after {
  display: none;
}
.s-alert-box {
  box-shadow: 0 5px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  padding: 15px 20px;
  font-size: 14px;
}
span.s-alert-message {
  font-family: "Proxima Nova", Arial, sans-serif;
}
.s-alert-box-inner {
  display: flex;
  align-items: center;
}
.s-alert-icon {
  margin-right: 10px;
}
